import React from "react"
import About from "../components/About"
import GetStart from "../components/Getstart"
import HowItWork from "../components/HowItWork"
import ContactUs from "../components/ContactUs"
import HelpCenter from "../components/HelpCenter"
import Review from "../components/Review"
import Layout from "../components/Layout"
import SmallContent from "../components/SmallContent"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import "../styles/mm-font.css"
import Fade from "react-reveal/Fade"

export const query = graphql`
  query indexMmQuery {
    allReviewDataMmJson {
      nodes {
        title
      }
    }
    allReviewPostsJson {
      nodes {
        posts
      }
    }
    allSmallContentMmJson {
      nodes {
        name
        content
        icon
      }
    }
    allAboutMmJson {
      nodes {
        name
        content
      }
    }
    allNavMmJson {
      nodes {
        name
        url
      }
    }
    allHowItWorkOneMmJson {
      nodes {
        name
        content
      }
    }
    allHowItWorkTwoMmJson {
      nodes {
        name
        content
        icon
      }
    }
    allIndexDataMmJson {
      nodes {
        heading
        title
        helpCenter
        contactUs
      }
    }
    allHelpCenterMmJson {
      nodes {
        name
        content
        icon
      }
    }
    allStepTitleMmJson {
      nodes {
        title
      }
    }
    allContactNumbersJson {
      nodes {
        phone
        fax
      }
    }
    allFooterNavMmJson {
      nodes {
        name
        url
      }
    }
    image1: file(relativePath: { eq: "screenshots/mm/01.png" }) {
      childImageSharp {
        fixed(width: 360) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image2: file(relativePath: { eq: "screenshots/mm/02.png" }) {
      childImageSharp {
        fixed(width: 360) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image3: file(relativePath: { eq: "screenshots/mm/03.png" }) {
      childImageSharp {
        fixed(width: 360) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image4: file(relativePath: { eq: "screenshots/mm/04.png" }) {
      childImageSharp {
        fixed(width: 360) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image5: file(relativePath: { eq: "screenshots/mm/05.png" }) {
      childImageSharp {
        fixed(width: 360) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default ({ data }) => {
  let images = []
  images[0] = data.image1
  images[1] = data.image2
  images[2] = data.image3
  images[3] = data.image4
  images[4] = data.image5

  const userGuide = "UserGuide_MM/Slide"

  return (
    <Layout
      title="ပင်မ စာမျက်နှာ"
      nav={data.allNavMmJson.nodes}
      headingData={data.allIndexDataMmJson.nodes[0].heading}
      footer={data.allFooterNavMmJson.nodes}
    >
      <SEO title="ပင်မ စာမျက်နှာ" />
      <SmallContent contents={data.allSmallContentMmJson.nodes} />
      <div className="content">
        <Fade>
          <About abouts={data.allAboutMmJson.nodes} />
          <GetStart
            getStart={data.allIndexDataMmJson.nodes[0].title[0]}
            downloadApp={data.allIndexDataMmJson.nodes[0].title[1]}
            stepTitles={data.allStepTitleMmJson.nodes}
            images={images}
            userGuide={userGuide}
          />
          <HowItWork
            howItWorkOne={data.allHowItWorkOneMmJson.nodes}
            howItWorkTwo={data.allHowItWorkTwoMmJson.nodes}
            howItWorkData={data.allIndexDataMmJson.nodes[0].title[2]}
          />
          <HelpCenter
            helpCenter={data.allHelpCenterMmJson.nodes}
            helpCenterData={data.allIndexDataMmJson.nodes[0].helpCenter}
          />
          <Review
            reviewPosts={data.allReviewPostsJson.nodes[0].posts}
            reviewData={data.allReviewDataMmJson.nodes[0]}
          />
          <ContactUs
            contactUsData={data.allIndexDataMmJson.nodes[0].contactUs}
            contactNumbers={data.allContactNumbersJson.nodes[0]}
          />
        </Fade>
      </div>
    </Layout>
  )
}
